var App = App || {};

App.Alert = (function ($) {
    'use strict';

    var $alerts;

    function init() {
        $alerts = $('.site-alert');

        $alerts.each(function () {
            var $alert = $(this),
                alertHash = $alert.data('alert-hash');

            if ($alert) {
                $alert.on('click', '.site-alert__dismiss', function (e) {
                    e.preventDefault();
                    dismissAlert($alert, alertHash);
                });
                $("#site-content").css("padding", $(".site-alert").outerHeight() - 10 + "px 0 0");
            }
        });
    }

    function dismissAlert($alert, alertHash) {
        $alert.slideUp(300);
        App.Cookies.setItem('AlertDismissed', alertHash, Infinity, '/', window.location.hostname, window.location.protocol === 'https:');
        $("#site-content").css("padding", "0");
    }

    function alertDismissed(alertHash) {
        return App.Cookies.getItem('AlertDismissed') === alertHash ? true : false;
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Alert.init();
});
var App = App || {};

App.Carousel = (function ($) { 

    var $controls = $('.carousel-controls');

    function init() {
        $controls.on('click', '.carousel-btn--play', function () {
            var $banner = $(this).parents('.banner-component');

            $banner.find('.slick-initialized').slick('slickPlay');
            $banner.find('.carousel-btn--pause').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.carousel-btn--play').addClass('d-none').attr('tabindex', '-1');
        });

        $controls.on('click', '.carousel-btn--pause', function () {
            var $banner = $(this).parents('.banner-component');

            $banner.find('.slick-initialized').slick('slickPause');
            $banner.find('.carousel-btn--play').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.carousel-btn--pause').addClass('d-none').attr('tabindex', '-1');
        });
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Carousel.init();
});

var App = App || {};

App.Cookies = (function () {
    'use strict';

    function getItem(sKey) {
        if (!sKey) { return null; }
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    }

    function setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        var sExpires = "";
        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                    break;
                case String:
                    sExpires = "; expires=" + vEnd;
                    break;
                case Date:
                    sExpires = "; expires=" + vEnd.toUTCString();
                    break;
            }
        }
        document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
        return true;
    }

    function removeItem(sKey, sPath, sDomain) {
        if (!this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
    }

    function hasItem(sKey) {
        if (!sKey) { return false; }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }

    function keys() {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
        return aKeys;
    }

    return {
        getItem: getItem,
        setItem: setItem,
        removeItem: removeItem,
        hasItem: hasItem,
        keys: keys
    };
})();
var App = App || {};

App.Forms = (function ($) {

    var $form = $('.umbraco-forms');

    function init() {
        var $summary = $form.find('[class^="validation-summary"]');
        var $message = $('#submitmessage');

        if ($summary.length) {
            $summary.on('DOMSubtreeModified', function () {
                setTimeout(function () {
                    $form.find('.input-validation-error').first().focus();
                }, 1);
            });
        }

        if ($message.length) {
            setTimeout(function () {
                var offset = $message.offset.top + 30;
                $message.focus();
                window.scrollTo(offset, 0);
            }, 1);         
        }
    }
    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Forms.init();
});

var App = App || {};

App.Login = (function ($) {
    "use strict";

    var $loginModal,
        ignoreModal

    function init() {
        $loginModal = $("#loginModal");
        ignoreModal = App.Cookies.getItem("LoginModalDismissed");

        if (!$loginModal.length || ignoreModal) {
            return;
        }

        $("a#loginToggle").on("click", function (e) {
            var $this = $(this);
            var url = $this.attr("href");

            if (!ignoreModal) {
                e.preventDefault();
                $('#loginModal').modal('show');

                $("#loginModalSubmit").one("click", function (event) {
                    App.Cookies.setItem("LoginModalDismissed", true, Infinity, '/', window.location.hostname, window.location.protocol === 'https:');
                    event.preventDefault();
                    window.open(url);
                    $("#loginModal").modal("hide");
                });

                $("#loginModalEnroll").one("click", function (event) {
                    event.preventDefault();
                    window.open(url + "registration");
                });
            }        
        });
    }
    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Login.init();
});
var App = App || {};

App.MobileApp = (function ($) {

    function init() {

        new SmartBanner({
            daysHidden: 15,
            daysReminder: 90,
            appStoreLanguage: 'us',
            title: 'CommonWealth',
            author: 'CommonWealth',
            button: 'VIEW',
            store: {
                ios: 'On the App Store',
                android: 'In Google Play'
            },
            price: {
                ios: 'FREE',
                android: 'FREE'
            }
        });

        var $smartbanner = $(".smartbanner"),
            $smartbannerButton = $smartbanner.find(".smartbanner-button"),
            $smartbannerContainer = $(".smartbanner-container");

        $smartbanner.prependTo("header#site-header");
        $smartbannerButton.addClass("btn btn-sm btn-primary");
        $smartbannerContainer.addClass("container");
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.MobileApp.init();
});
var App = App || {};

App.Navigation = (function ($) {
    var $main = $('nav.main');

    function init() {
        $main.find('.has-child').on('focusin', function () {
            var $this = $(this);
            $this.addClass('open-child open-child_mobile');
            $(this).find("> span > a").attr("aria-expanded", true);
        });

        $main.find('.has-child').on('focusout', function (e) {
            var $this = $(this);

            if (!$.contains(this, e.relatedTarget)) {
                $this.removeClass('open-child open-child_mobile');
                $(this).find("> span > a").attr("aria-expanded", false);
            }
        });

        $main.find('.has-child').each(function () {
            var children = $(this).find("ul").children("li").length;

            $(this).find("> span > a").attr("aria-label", "Item with " + children + " children");
        });

        //global nav
        if ($("nav.secondary").children(".dropdown-toggle").length !== -1) {
            if ($(window).width() < 992) {
                $("nav.secondary .dropdown-toggle").dropdown('dispose');
            }
        }

        $(window).on("load", function () {
            var hash = window.location.href.split("#")[1],
                offset = $("#site-header").outerHeight();

            if (hash) {
                $(window)
                    .stop(true)
                    .scrollTo($("#" + hash), { duration: 400, interrupt: false, offset: offset * -1 });
            }
        });

        $('a').on('click', function (e) {
            var $this = $(this);
            var url = $this.attr('href');


            // The default behavior for non white-listed external links (excludes tel, mailto and javascript links)
            if (this.hostname === location.hostname
                && url
                && url.indexOf('mailto:') === -1
                && url.indexOf('tel:') === -1
                && url.indexOf('javascript:') === -1
                && url.indexOf('youtube') === -1
                && url.indexOf('img') === -1) {

                var hash = url.split("#")[1],
                    offset = $("#site-header").outerHeight();

                if (hash) {
                    $(window)
                        .stop(true)
                        .scrollTo($("#" + hash), { duration: 400, interrupt: false, offset: offset * -1 });
                }
            }
        });

    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Navigation.init();
});
var App = App || {};

App.RatesBand = (function ($) {

    function init() {
        setTimeout(
            function () {
                $(".rates-band-component").find('.rates-value').each(function () {
                    if ($(this).text().indexOf("%") >= 0) {
                        $(this).text($(this).text().toString().replace("%", ""));
                    }
                });
            }, 3000);
    }

    return {
        init: init
    };


})(jQuery);

$(function () {
    App.RatesBand.init();
});

var App = App || {};

App.Speedbump = (function ($) {
    'use strict';

    var $speedbump,
        whitelist;

    function init() {
        $speedbump = $('#sbModal');

        if (!$speedbump.length) {
            return;
        }

        whitelist = $speedbump.data("exceptions").split("\n");

        $('a').on('click', function (e) {
            var $this = $(this);
            var url = $this.attr('href');
            var i = 0;
            var initialUrl = url;
            var whitelistedLink = false;

            //If the link is inside the speedbump modal
            if ($this.attr('id') === "sbModalSubmit") {
                e.preventDefault();
                //ga("send", "event", "Outgoing Links", url, document.location.pathname + document.location.search);
                window.open(url);
                $('#sbModal').modal('hide');
            }


            // The default behavior for non white-listed external links (excludes tel, mailto and javascript links)
            else if (this.hostname !== location.hostname
                && url
                && url.indexOf('mailto:') === -1
                && url.indexOf('tel:') === -1
                && url.indexOf('javascript:') === -1
                && url.indexOf('youtube') === -1
                && url.indexOf('img') === -1) {

                // process url
                if (typeof url === "string") {
                    url = url.replace("http://", "").replace("http://", "").replace("https://", "").replace("www.", "").replace("www2.", "");
                }

                for (i = 0; i < whitelist.length; ++i) {
                    whitelist[i] = whitelist[i].replace("http://", "").replace("http://", "").replace("https://", "").replace("www.", "").replace("www2.", "");

                    if (url.startsWith(whitelist[i])) {
                        whitelistedLink = true;
                        break;
                    }
                }

                if (!whitelistedLink) {
                    e.preventDefault();
                    $('#sbModal').modal('show');
                    $('#sbModalSubmit').attr('href', initialUrl);
                }

                //Add a Speedbump for these two URLs
                var domain = url.split('/')[2];

                if (domain === "www.greenpath.com" && domain === "www.cofcufn.org") {
                    e.preventDefault();
                    $('#sbModal').modal('show');
                    $('#sbModalSubmit').attr('href', initialUrl);
                }
            }

        });

    }
    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Speedbump.init();
});
var App = App || {};

App.Video = (function ($) {
    var $controls = $('.video-controls');

    function init() {
        $controls.on('click', '.video-btn--play', function () {
            var $banner = $(this).parents('.item');

            $banner.find('.video-player').get(0).play();
            $banner.find('.video-btn--pause').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.video-btn--play').addClass('d-none').attr('tabindex', '-1');
        });

        $controls.on('click', '.video-btn--pause', function () {
            var $banner = $(this).parents('.item');

            $banner.find('.video-player').get(0).pause();
            $banner.find('.video-btn--play').removeClass('d-none').attr('tabindex', '0').focus();
            $banner.find('.video-btn--pause').addClass('d-none').attr('tabindex', '-1');
        });
    }

    return {
        init: init
    };
})(jQuery);

$(function () {
    App.Video.init();
});
"use strict";

(function () {

    var app = angular.module("EventList_App", ['ngSanitize']);

    angular.element(document).ready(function () {
        var ngEventListApp = document.getElementById('ngEventListApp');
        angular.bootstrap(ngEventListApp, ['EventList_App']);
    });

})();
"use strict";

(function () {

    function EventList_Ctl($scope, $http, EventList_Svc) {

        $scope.List = [];
        $scope.Month = "0-0";
        $scope.Category = "";
        $scope.Months = [];
        $scope.Categories = [];
        $scope.ExcludePastEvents = { checked : true };
        $scope.Calendar = {};

        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];

        function GetEvents(page, year, month, category, excludePastEvents) {

            var params = {
                "page": page,
                "year": year,
                "month": month,
                "category": category,
                "excludePastEvents": excludePastEvents
            };

            EventList_Svc.GetEvents(params)
                .then(
                    function (result) {
                        $scope.List = [];

                        for (var key in result) {
                            $scope.List.push(result[key]);
                        }
                    }
                );
        }

        function GetEventMonths(page) {

            var params = {
                "page": page
            };

            EventList_Svc.GetEventMonths(params)
                .then(
                    function (result) {
                        $scope.Months = [];

                        for (var key in result) {
                            $scope.Months.push(result[key]);
                        }
                    }
                );
        }

        function GetEventCategories(page) {

            var params = {
                "page": page
            };

            EventList_Svc.GetEventCategories(params)
                .then(
                    function (result) {
                        $scope.Categories = [];

                        for (var key in result) {
                            $scope.Categories.push(result[key]);
                        }
                    }
                );
        }

        function GetCalendar(page, year, month) {

            var params = {
                "page": page,
                "year": year,
                "month": month + 1,
                "category": "",
                "excludePastEvents": false
            };

            EventList_Svc.GetEvents(params)
                .then(
                    function (result) {
                        RefreshCalendar(result, year, month);
                    }
                );
        }

        function RefreshCalendar(events, year, month) {
            $scope.Calendar["year"] = year;
            $scope.Calendar["month"] = month;
            $scope.Calendar["monthName"] = monthNames[month];
            $scope.Calendar["weeks"] = {};
            $scope.Calendar["weeks"][0] = {};
            $scope.Calendar["weeks"][0]["days"] = {};
            $scope.Calendar["classes"] = "";

            var startDayOfWeek = new Date(year, month, 1).getDay();
            var weekNum = 0;
            var dayOfWeekNum = 0;
            var dateNum = 1;
            var lastDateNum = new Date(year, month + 1, 0).getDate();

            for (var i = 0; i < startDayOfWeek; i++) {
                $scope.Calendar["weeks"][weekNum]["days"][dayOfWeekNum] = {
                    "label": ""
                };
                dayOfWeekNum++;
            }

            for (dateNum; dateNum <= lastDateNum; dateNum++) {

                $scope.Calendar["weeks"][weekNum]["days"][dayOfWeekNum] = {
                    "label": dateNum,
                    "events": GetEventsDay(events, year, month, dateNum)
                };

                if ($scope.Calendar["weeks"][weekNum]["days"][dayOfWeekNum]["events"].length) {
                    $scope.Calendar["classes"] = "has-events";
                    $scope.Calendar["weeks"][weekNum]["days"][dayOfWeekNum]["classes"] = "has-events";
                }

                var today = new Date();
                if (today.getDate() === dateNum && today.getMonth() === month && today.getFullYear() === year) {
                    $scope.Calendar["weeks"][weekNum]["days"][dayOfWeekNum]["classes"] += " is-today";
                }

                dayOfWeekNum++;

                if (dayOfWeekNum > 6) {
                    dayOfWeekNum = 0;
                    weekNum++;
                    $scope.Calendar["weeks"][weekNum] = {};
                    $scope.Calendar["weeks"][weekNum]["days"] = {};
                }
            }

            for (dayOfWeekNum; dayOfWeekNum !== 0 && dayOfWeekNum <= 6; dayOfWeekNum++) {
                $scope.Calendar["weeks"][weekNum]["days"][dayOfWeekNum] = {
                    "label": ""
                };
            }
        }

        function GetEventsDay(events, year, month, day) {

            var startDateTime = new Date(year, month, day, 0, 0, 0, 0);
            var endDateTime = new Date(year, month, day, 23, 59, 59, 999);
            var filteredEvents = [];
            for (var key in events) {
                if (new Date(events[key].Start) <= endDateTime && new Date(events[key].End) >= startDateTime) {
                    filteredEvents.push(events[key]);
                }
            }
            return filteredEvents;
        }

        $scope.UpdateEvents = function (page) {
            var year = $scope.Month.split("-")[0];
            var month = $scope.Month.split("-")[1];
            var category = $scope.Category;
            var excludePastEvents = $scope.ExcludePastEvents.checked;

            GetEvents(page, year, month, category, excludePastEvents);
        };

        $scope.PreviousCalendar = function (page) {
            $scope.Calendar["month"]--;
            if ($scope.Calendar["month"] === -1) {
                $scope.Calendar["month"] = 11;
                $scope.Calendar["year"]--;
            }
            GetCalendar(page, $scope.Calendar["year"], $scope.Calendar["month"]);
        };

        $scope.NextCalendar = function (page) {
            console.log($scope.Calendar["month"]);
            $scope.Calendar["month"]++;
            if ($scope.Calendar["month"] === 12) {
                $scope.Calendar["month"] = 0;
                $scope.Calendar["year"]++;
            }
            GetCalendar(page, $scope.Calendar["year"], $scope.Calendar["month"]);
        };


        // INIT
        $scope.init = function (page) {
            GetEvents(page, "0", "0", "", true);
            GetEventMonths(page);
            GetEventCategories(page);

            var date = new Date();
            GetCalendar(page, date.getFullYear(), date.getMonth());
        };
    }

    angular
        .module("EventList_App")
        .controller("EventList_Ctl", ["$scope", "$http", "EventList_Svc", EventList_Ctl]);
})();
(function () {

    var EventList_Svc = function ($http, $window) {

        var GetEvents = function (params) {
            var config = { headers: { 'Content-Type': 'application/xml' }, params: params };
            return $http.get('/umbraco/api/EventsApi/GetEvents', config)
                .then(
                    // success
                    function (response) {
                        return response.data;
                    },
                    // failure
                    function (response) {
                        return response.data;
                    }
                );
        };

        var GetEventMonths = function (params) {
            var config = { headers: { 'Content-Type': 'application/xml' }, params: params };
            return $http.get('/umbraco/api/EventsApi/GetEventMonths', config)
                .then(
                    // success
                    function (response) {
                        return response.data;
                    },
                    // failure
                    function (response) {
                        return response.data;
                    }
                );
        };

        var GetEventCategories = function (params) {
            var config = { headers: { 'Content-Type': 'application/xml' }, params: params };
            return $http.get('/umbraco/api/EventsApi/GetEventCategories', config)
                .then(
                    // success
                    function (response) {
                        return response.data;
                    },
                    // failure
                    function (response) {
                        return response.data;
                    }
                );
        };

        return {
            GetEvents: GetEvents,
            GetEventMonths: GetEventMonths,
            GetEventCategories: GetEventCategories
        };
    };

    var module = angular.module("EventList_App");
    module.factory("EventList_Svc", ["$http", "$window", EventList_Svc]);

}());